import React from "react"
import { Link } from "gatsby"
import { navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const RetouchPage = () => (
  <Layout>
    <SEO title="スクールフォト" />
    <div
      className="flex h-[20vh] bg-cover bg-center bg-opacity-25 justify-center content-center"
      style={{ backgroundImage: `url('../schoolphoto-header.webp')` }}
    >
      <p className="text-white text-center text-3xl lg:text-4xl self-center">スクールフォト</p>
    </div>
    
    <div className="container space-y-5 px-5 lg:px-0 my-8 h-30 max-w-screen-md justify-center content-center">
      <div className="space-y-5">
        <p className="text-textblack text-center">
          アライヤ写真館では、園・学校の行事写真のネット閲覧サービスを提供しています。
        </p>
        <p className="text-textblack text-center">
          下記ログインボタンよりご利用ください。
        </p>

        <button class="bg-orange-800 hover:bg-orange-700 text-textblack py-4 px-20 rounded flex mx-auto" onClick={() => { window.open('https://11shot.com', '_blank') }}>
          <p className="text-white text-xl">ログイン</p>
        </button>

        <div className="container mx-auto px-5 max-w-screen-md space-y-3">
        <p className="text-textblack text-2xl underline decoration-2 underline-offset-8">注意事項</p>
        <ul class="list-disc ml-10">
          <li className="text-textblack">実際の写真は、画面に表示される画像より若干両端がカットされます。写真の端に顔が小さく写っているお子様はカットされる恐れがありますので、予めご了承ください。</li>
          <li className="text-textblack">写真申し込み袋には、保護者の方が濃く、はっきりとご記入ください。特に、「１」と「７」、「６」と「０」ははっきりとご記入ください。</li>
          <li className="text-textblack">写真閲覧サービスへのアクセスは、所定の通信料がかかります。</li>
          <li className="text-textblack">お客様の写真、個人情報が流出しないよう、強いセキュリティをかけています。そのため、画像に透かしが入っており、画像の保存、印刷等は一切できません。また、TLS暗号化技術により、通信はすべて暗号化されています。</li>
            <li className="text-textblack">写真の非掲載をご希望の方は、当店までご連絡ください。</li>
            <li className="text-textblack">すべてのデータは日本国内のサーバに保管されています。なお、ネット閲覧サービスに関わる業務について、株式会社イメージテックに委託することがあります。</li>
        </ul>
      </div>
      </div>
    </div>
  </Layout>
)

export default RetouchPage